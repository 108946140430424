// method for parallel async module loading
import parallelDynamicImport from "@/client/utilities/parallelDynamicImport.js";

const isSSR = utilities.isSSR();
const usingSSR =
  process.env.VUE_APP_USE_SSR && process.env.VUE_APP_USE_SSR !== "false";

const appName = process.env.VUE_APP_APPLICATION_NAME;

const {
  getApplicationParts,
  getApplicationPartsSSR,
} = require("@/client/applications/" + appName + "/" + appName + ".js");

let saffronAppGlobalKey = config.saffronAppGlobalKey;

const createApplication = async (context) => {
  // import all the stuff we need (application exports, and createApp, using a single await for maximum performance
  let importDefinition = [
    { importKey: "createApp", targetKey: "createApp", promise: import("vue") },
    {
      importKey: "createSSRApp",
      targetKey: "createSSRApp",
      promise: import("vue"),
    },
  ];
  let isCrawler;

  if (utilities.isSSR() && context) {
    isCrawler = !!context?.isCrawler;
  } else {
    isCrawler = !!window.__SAFFRON_CRAWLER_DETECTED__;
  }

  let imports = await Promise.allSettled([
    getApplicationParts(),
    parallelDynamicImport(importDefinition),
  ]);

  let parts = imports[0].value;
  let createApp;

  if (usingSSR) {
    createApp = imports[1].value.createSSRApp; // for any SSR app - we need this. on server it renders, on client it hydrates
  } else {
    createApp = imports[1].value.createApp; // spa
  }

  // create the application
  let application = createApp(parts.app);

  application.config.globalProperties.isCrawler = isCrawler;

  application.isSaffronHydrating = usingSSR && !isSSR;
  parts.store.isSaffronMounted = false;
  parts.store.isSaffronHydrating = application.isSaffronHydrating;

  application.provide(saffronAppGlobalKey, {});
  application.provide("isSaffronHydrating", application.isSaffronHydrating);

  if (parts.store) {
    application.use(parts.store);
    application.store = parts.store;

    // fetch config if needed
    // do not check config via store, becuase the module may not exist in app. if the useServerConfig is on,
    // assume app developer took care of it and left us the correct store API
    if (config.useServerConfig && config.waitForServerConfig) {
      await parts.store.dispatch("config/updateFromServer", {
        allowLoadFromWindow: true,
      });
    }

    if (config.useServerConfig && !config.waitForServerConfig) {
      parts.store.dispatch("config/updateFromServer");
    }
    parts.store.isSSRHydrated = false;

    // store hydration
    if (usingSSR && !isSSR) {
      if (window && window.getInitialSaffronStoreState) {
        let ssrState = getInitialSaffronStoreState();
        parts.store.replaceState(ssrState);
        parts.store.isSSRHydrated = true;
      }
    }
  }

  // create router, if the key is a method
  if (parts.router && typeof parts.router === "function") {
    parts.router = parts.router(application);
  }

  if (parts.router) {
    application.use(parts.router);
  }

  if (parts.router && parts.store) {
    parts.router.$store = parts.store;
    parts.router.isStoreSet = true;
    parts.store.$router = parts.router;
    parts.store.isRouterSet = true;
  }

  // apply all the extensions it asked for
  for (const [index, extension] of Object.entries(parts.extensions || {})) {
    application.use(extension);
  }

  let result = {
    app: application,
    router: parts.router || false,
    store: parts.store || false,
  };

  if (context && typeof context.isMobileUa !== "undefined") {
    application.provide("isMobileUa", context.isMobile);
  }

  if (context && typeof context.cookies !== "undefined") {
    try {
      application.injectServerCookies(context.cookies);
      parts.store.cookie.injectServerCookies(context.cookies);
    } catch (e) {
      console.log("error injecting cookies", e);
    }
  }

  // SSR routing, injection
  if (context && context?.url && parts.router) {
    // handle SSR routing
    return await new Promise((fulfil) => {
      parts.router.push(context.url);
      parts.router.isReady().then(() => {
        fulfil(result);
      });
    });
  } else {
    return result;
  }
};

let mountAppClientSide = async (app, router, store) => {
  // no ssr - mount, handle service worker
  if (!usingSSR) {
    try {
      window.saffronPreloadSpinnerRemove();
    } catch (e) {}

    app.mount("#app");
    if (config.useServiceWorker) {
      require("./registerServiceWorker");
    } else {
      require("./unregisterServiceWorker");
    }

    return;
  }

  // ssr - start doing complex magic (which was greatly simplified from the more complex magic that did not work)
  // this is a temporary measure. When mount is populating the #app - it blows up and delete the whole #app element
  if (!config.hydrateBodyInSsr) {
    document.querySelector("#app").innerHTML = "";
  }

  let activationInterval;
  let activateSaffronIfMounted = () => {
    setTimeout(() => {
      app.isSaffronHydrating = false;
      app.store.isSaffronHydrating = false;
    }, 500);

    if (app.store.isSaffronMounted) {
      // stop tracking mounting - we are done
      clearInterval(activationInterval);

      try {
        // try to remvoe global spinner it is scheduled to show
        window.saffronPreloadSpinnerRemove();
      } catch (e) {}

      // if server hid the body to prevent flickering - show it. otherwise - no harm.
      document.body.style.opacity = "1";

      // wait a moment (for flickering/animation)
      setTimeout(() => {
        // body classes to enable routing animations
        document.querySelector("#app").classList.add("hydrated");
        document.querySelector("#app").classList.add("activated");

        // tell the world we are ready
        window.dispatchEvent(new Event("saffronClientReady"));
      }, 10);
    }
  };

  // look for signal of being ready. then set some vars/classes. this is for correct state and routing animation/flickering fix
  activationInterval = setInterval(activateSaffronIfMounted, 25);

  // mount the app

  app.mount("#app");
  //
};

if (!isSSR) {
  // automatically mount app if this is on the client side
  createApplication().then(({ app, router, store }) => {
    mountAppClientSide(app, router, store);
  });
}

export { createApplication, getApplicationParts, getApplicationPartsSSR };
